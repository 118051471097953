import axios from 'axios';
import axiosRetry from 'axios-retry';
import { API_URL } from 'config/constants';
import { firebaseApp } from 'services/firebase';

axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

const iProTubeApi = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
    Accept: 'application/json',
  },
});

iProTubeApi.interceptors.request.use(request => {
  const user = firebaseApp.auth().currentUser;
  if (user) {
    return user.getIdTokenResult().then(({ token }) => {
      request.headers.Authorization = `Bearer ${token}`;
      request.headers['x-access-token'] = `${token}`;
      return Promise.resolve(request);
    });
  }

  return request;
});

export default iProTubeApi;
