import { AxiosRequestConfig } from 'axios';
import api from '../iProTubeApi';
import { User } from '../schemas/User.schema';
import { LoginToken } from '../schemas/LoginToken';
import { StoreUserDTO } from './types';

export async function storeClient(dto: StoreUserDTO): Promise<User> {
  return await api
    .post('/authentications/clients', dto)
    .then(({ data }) => data);
}

export async function storeTeacher(dto: StoreUserDTO): Promise<User> {
  return await api
    .post('/authentications/teachers', dto)
    .then(({ data }) => data);
}

export async function signInUsingGoogleClient(
  config?: AxiosRequestConfig,
): Promise<void> {
  return await api.post('/authentications/google/clients', config);
}

export async function getAuthenticationToken(): Promise<LoginToken> {
  return await api
    .get<LoginToken>('/authentications/login-token')
    .then(({ data }) => data);
}
