import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/messaging';
import { signInUsingGoogleClient } from '../apiServices/IProTubeApi/authentications';
import { firebaseConfig } from '../../config/firebase';

export const firebaseApp = !firebase.apps.length
  ? firebase.initializeApp(firebaseConfig)
  : firebase.app();

export const signInWithFirebaseUsingCredentials = async (
  email: string,
  password: string,
): Promise<firebase.User> => {
  return firebaseApp
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(async loggedUser => loggedUser.user);
};

export const signInWithFirebaseUsingPopup = async (): Promise<firebase.User> => {
  const googleProvider = new firebase.auth.GoogleAuthProvider();

  return firebaseApp
    .auth()
    .signInWithPopup(googleProvider)
    .then(async ({ user }) => {
      const userToken = await user.getIdToken();

      await signInUsingGoogleClient({
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      return user;
    });
};
